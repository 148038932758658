import { Controller } from "@hotwired/stimulus"
import HugeUploader from 'huge-uploader'

export default class extends Controller {
  static values = {
    url: String,
    method: { type: String, default: 'POST' },
  }

  upload(event) {
    const upload = new HugeUploader({
      endpoint: this.urlValue,
      headers: this.headers,
      file: event.target.files[0],
      chunkSize: 5, // in megabytes
      method: this.methodValue,
      inputName: event.target.name
    })

    upload.on('progress', event => {
      Turbo.renderStreamMessage(event.detail.body)
    })
  }

  get headers() {
    return {
      "X-CSRF-Token": this.token,
      "Accept": "text/vnd.turbo-stream.html"
    }
  }

  get token() {
    return document.head.querySelector("meta[name=csrf-token]")?.content
  }
}
