import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    turbo: { type: Boolean, default: true }
  }

  connect() {
    if (this.turboValue) { 
      Turbo.visit(window.location.href)
      return 
    }
    window.location.reload() 
  }
}
