import React from "react"
import ReactDOM from "react-dom/client"
import { flushSync } from "react-dom"
import { Controller } from "@hotwired/stimulus"
import { ReactSelectRenderer,
  ReactSelectAsyncRenderer,
  ReactSelectCreatableRenderer,
  ReactSelectCreatableAsyncRenderer
} from "./react_select/renderer"


/*
<select
  data-controller="react-select"
  data-react-select-new-value="true"
  data-react-select-url-value="/json">
</select>
*/


export default class extends Controller {
  static values = {
    new: Boolean,
    url: String,
    clear: Boolean,
    theme: String,
    cache: { type: Boolean, default: true } 
  }

  initialize() {
    if (this.data.get("initialized")) {
      return
    }
    this.data.set("initialized", true)
    this.createWrapper()
    this.initReactSelect()
  }

  createWrapper() {
    this.wrapper = document.createElement('div')
    this.wrapper.className = this.element.className
    this.element.className = ''
    this.element.style.display = "none"
    this.element.parentNode.insertBefore(this.wrapper, this.element)
    this.wrapper.appendChild(this.element)
  }

  initReactSelect() {
    const reactSelect = document.createElement("div")
    this.wrapper.appendChild(reactSelect);
    const root = ReactDOM.createRoot(reactSelect)
    flushSync(() => {
      root.render(this.renderInstance().render())
    })
  }

  renderInstance() {
    if (this.newValue && this.urlValue) { return new ReactSelectCreatableAsyncRenderer(this) }
    if (this.newValue) { return new ReactSelectCreatableRenderer(this) }
    if (this.urlValue) { return new ReactSelectAsyncRenderer(this) }

    return new ReactSelectRenderer(this)
  }

  loadOptionsUrl(query, page) {
    const url = new URL(this.urlValue, window.location)
    url.searchParams.append("query", query)
    url.searchParams.append("page", page)
    return url
  }

  get headers() {
    return {
      "Accept": "application/json",
    }
  }

  async loadOptions(query, loadedOptions, { page } = { page: 1 }) {
    console.log("LOADING OPTIONS", page)
    const loadOptionValues = loadedOptions.map(option => option.value)
    const response = await fetch(this.loadOptionsUrl(query, page), { headers: this.headers })
    const responseJSON = await response.json()

    const options = responseJSON.results.
      map(result => { return { value: result.id, label: result.text } }).
      filter(option => !loadOptionValues.includes(option.value))

    return {
      options: options,
      hasMore: responseJSON.pagination.more,
      additional: { page: page + 1 }
    }
  }

  updateSelect(options) {
    options = [options].flat().filter(option => option)
    this.select.length = 0

    for (let option of options) {
      this.select.add(this.optionToHtml(option))
    }

    this.select.dispatchEvent(new Event("change", { bubbles: true, cancelable: true }))
  }

  get isMulti() {
    return this.select.getAttribute("multiple") != null
  }

  get select() {
    return this.element
  }

  get options() {
    return [...this.select.children].map(this.htmlToOption.bind(this))
  }

  get selectedOptions() {
    return [...this.select.selectedOptions].map(this.htmlToOption.bind(this))
  }

  get placeholder() {
    return this.select.getAttribute("placeholder")
  }

  optionToHtml(option, selected = true) {
    const htmlOption = document.createElement('option')
    htmlOption.value = option.value
    htmlOption.text = option.label
    htmlOption.selected = selected
    return htmlOption
  }

  htmlToOption(option) {
    if (option.nodeName == "OPTGROUP") {
      return { label: option.label, options: [...option.children].map(this.htmlToOption.bind(this)) }
    }

    const value = isNaN(option.value) || option.value === "" ? option.value : parseInt(option.value)
    return { value: value , label: option.text }
  }
}
