import React from "react"
import Select from "react-select"
import CreatableSelect from 'react-select/creatable'
import { AsyncPaginate, withAsyncPaginate } from 'react-select-async-paginate'
import AsyncPaginateControlCache from "./async_paginate_control_cache"
const CreatableAsyncPaginate = withAsyncPaginate(CreatableSelect)


class Renderer {
  constructor(controller) {
    this.controller = controller
  }

  defaultProps() {
    return {
      'classNames': this.classNames,
      'className': 'react-select-container',
      'classNamePrefix': 'react-select',
      'options': this.controller.options,
      'defaultValue': this.controller.selectedOptions,
      'onChange': this.controller.updateSelect.bind(this.controller),
      'isMulti': this.controller.isMulti,
      'isClearable': this.controller.clearValue,
      'placeholder': this.controller.placeholder,
      'loadingMessage': () => 'Szukam...',
      'noOptionsMessage': () => 'Brak opcji',
      'createOptionPosition': 'first',
      'formatCreateLabel': (inputValue) => `Dodaj '${inputValue}'`,
      'unstyled': this.unstyled
    }
  }

  classNamesOption(state) {
    if(state.isSelected) return 'bg-orange-400 text-white p-2 hover:bg-orange-200 hover:text-black'
    if(state.isFocused) return 'p-2 bg-orange-200'

    return 'p-2 hover:bg-orange-200'
  }

  get unstyled() {
    return this.controller.themeValue == "tailwind"
  }

  get classNames() {
    if(!this.unstyled) return {}

    return {
      'clearIndicator': () => '',
      'container': () => '',
      'control': () => 'border border-gray-300 p-2',
      'dropdownIndicator': () => '',
      'group': () => 'relative',
      'groupHeading': () => 'p-2 uppercase text-xs font-semibold bg-gray-100 text-gray-500 sticky top-0',
      'indicatorsContainer': () => 'text-gray-500',
      'indicatorSeparator': () => 'border-r border-gray-300 pe-2 me-2',
      'input': () => '*:focus:ring-0',
      'loadingIndicator': () => '',
      'loadingMessage': () => '',
      'menu': () => 'mt-2 border border-gray-300 bg-white',
      'menuList': () => 'my-1',
      'menuPortal': () => '',
      'multiValue': () => 'bg-gray-100 px-2 text-sm py-0.5',
      'multiValueLabel': () => '',
      'multiValueRemove': () => 'ms-1',
      'noOptionsMessage': () => 'p-2',
      'option': this.classNamesOption.bind(this),
      'placeholder': () => '',
      'singleValue': () => '',
      'valueContainer': () => 'gap-2',
    }
  }
}

export class ReactSelectRenderer extends Renderer {
  render() {
    return React.createElement(Select, { ...this.defaultProps() } , null)
  }
}

export class ReactSelectCreatableRenderer extends Renderer {
  render() {
    return React.createElement(CreatableSelect, { ...this.defaultProps() } , null)
  }
}

export class ReactSelectAsyncRenderer extends Renderer {
  get component() {
    return AsyncPaginate
  }

  props() {
    return {
      ...this.defaultProps(),
      "loadOptions": this.controller.loadOptions.bind(this.controller),
      "options": this.controller.selectedOptions,
      "cache": this.controller.cacheValue,
      "component": this.component,
    }
  }
  render() {
    return React.createElement(AsyncPaginateControlCache, { ...this.props() } , null)
  }
}

export class ReactSelectCreatableAsyncRenderer extends ReactSelectAsyncRenderer  {
  get component() {
    return CreatableAsyncPaginate
  }
}
