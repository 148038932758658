/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const $ = jQuery;

class FileUploader {
  static attach() {
    return $(document).on("change", "[data-behavior~=file-uploader]", this.upload);
  }
  
  static upload(event) {
    Array.from(this.files).forEach(file => {
      FileUploader.uploadOne(this, file)
    });
  }

  static uploadOne(element, file) {
    if (file) {
      const form = new FormData;
      form.append(element.name, file);
      $(element).parent(".btn-file").addClass("loading");
      return $.ajax({
        contentType: false,
        url: $(element).data("url"),
        type: $(element).data("method") || "POST",
        data: form,
        processData: false,
        complete: () => {
          return $(element).parent(".btn-file").removeClass("loading");
        }
      });
    }
  }
}

FileUploader.attach();
