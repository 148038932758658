import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "textInput", "dateInput" ]

  connect() {
    this.updateDateInput()
  }

  updateTextInput(event) {
    this.textInputTarget.value = this.dateInputTarget.value
  }

  updateDateInput(event) {
    this.dateInputTarget.value = this.textInputTarget.value
  }

  open(event) {
    this.dateInputTarget.focus()
    this.dateInputTarget.showPicker()
  }
}
