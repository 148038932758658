import Popover from "./popover_controller.js"

export default class extends Popover {
  connect () {
    this.contentValue = this.element.title
    this.placementValue = "top"
    this.element.addEventListener("mouseenter", this.show.bind(this))
    this.element.addEventListener("mouseleave", this.hide.bind(this))
  }

  get tooltipClasses() {
    return this.tailwindClasses.concat(this.boostrapClasses)
  }

  get tailwindClasses() {
    return ["bg-black", "text-gray-50", "max-w-64", "p-2", "text-center", "text-sm"]
  }

  get boostrapClasses() {
    return ["text-light"]
  }
}
